@use "src/_theme.scss";
@use "src/_utils.scss";
@mixin Pool ($PoolWidth) {
.pool {
  $w: $PoolWidth;
  $poolFontSize: $w/25;
  $gutter: $poolFontSize;
  position: relative;
  font-size: $poolFontSize;

  display: inline-block;
  margin: $gutter;

  box-sizing: border-box;
  
  border-radius: $gutter;
  width: $w;
  padding: $gutter;

  color: theme.$fontColor;

  
  &.poolsvg--false {
    border: 1px solid rgba(theme.$borderColor, 0.5);
    background: none;
    &.pool--perm {
    border-color: theme.$theme;
  }
    &.pool--closed {
      background: rgba(red, 0.6);
      &::after {
        content: "REWARDS ENDED";
        display: inline-block;

      }
      .pool__controls {
        .pool__btn + .pool__btn {
          display: none;
        }
      }

    }

    &.pool--open {
     
    } 
  }
  
  &__name {
    img {
      height: $poolFontSize*2;
      margin-left: $poolFontSize/4;
      position: relative;
      top: $poolFontSize/2 - 2px + $poolFontSize/8;
      filter: drop-shadow(2px 2px 2px black);
    }
    img.moonwasp {
      filter: drop-shadow(0 0 8px theme.$specialFontColor);
    }
  }

  .arrows {
    margin-right: $poolFontSize/2;
    margin-left: $poolFontSize/2;
    color: theme.$effectColor;
  }

  &__textName {
    display: inline-block;
    font-size: $poolFontSize*2.5;
     @include theme.titleFont;
    @include theme.poolTitleFontColor;
    font-weight: 400;

    margin-top: $poolFontSize;
    margin-bottom: $poolFontSize/8;
  }

  &__rewards {
    margin-top: $poolFontSize/10;
    border-top: 1px dashed theme.$borderColor;
    border-bottom: 1px dashed theme.$borderColor;
    padding-top: $poolFontSize/10;
    padding-bottom: $poolFontSize/10;
    margin-bottom: $poolFontSize/10;
  }


  button {
    @include utils.buttonSizing($poolFontSize);
    @include utils.buttonStyling;

    margin: 2px $poolFontSize/4;
    cursor: pointer;
    transform: scale(1);
    


  
  }

  .pool__controls {
    text-align: right;
    margin-top: $gutter;
    a {
      display: inline-block;
    text-decoration: none;
   
    font-weight: 600;
    margin-right: 16px;
    @include theme.gradientText(theme.$theme2,theme.$theme3);
    filter: drop-shadow(2px 2px 2px black);
    transform: rotateZ(10deg);
    transition: 0.2s transform ease-in-out;
    &:hover {
      
      transform: rotateZ(0deg);
    }
    & + a {
      margin-left: 16px;
    }
  }
  }

  &__rate {
    position: absolute;
    top: $gutter;
    right: $gutter;
    font-size: $poolFontSize*2/3;
    padding: $poolFontSize/8 $poolFontSize/4;
    background: none;
  }



}

.svg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-16px);
}
svg {position: absolute; width: 100%; height: 100%; overflow: visible; } 
  polygon {width: 100%;}
          stop.stop1 { stop-color: theme.$themeSaturated; }
          .stop2 { stop-color: theme.$theme3; stop-opacity: 0.5; }
          .stop3 { stop-color: theme.$theme2; }

          .stop4 {stop-color: theme.$theme;  stop-opacity: 0.7; }
          .stop5 {stop-color: scale-color(theme.$theme,$lightness:10%);  stop-opacity: 0.7; }

}

